import React from "react"
import Container from "../components/Container";
import Head from "../components/Head";
import Button from "../components/Button"
import QRCode from "react-qr-code"
import copy from '../assets/svg/copy';
import DownloadApps from "../components/DownloadApps";
import {
  useLocation,
} from "react-router-dom"
import PrivacyButtons from "../components/PrivacyButtons";

const Code = () => {
  const { pathname } = useLocation();

  const domain = window.location.hostname;
  const currentDomain = domain === "acst.app" ? "accessticket.app" : "acst.app";

  const PathnameParts = pathname?.split('/')
  const TICKETID = PathnameParts[PathnameParts.length - 1]

  function copyText() {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(TICKETID);
    }
  }
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(TICKETID);
      alert(`Copied to clipboard: ${TICKETID}`);
    } catch (err) {
      alert('Error al copiar el texto: ', err);
    }
  };

  return (
    <Container>
      {/* DESKTOP */}
      <Head paragraphsClassName="hidden md:block" paragraphs={[
        `Scan this QR to download the app and add your ticket, or manually introduce the following code:`
      ]} />
      <span className="hidden md:block text-white font-bold text-2xl">{TICKETID}</span>
      {/* QR */}
      <div className="hidden mt-12 md:block">
        <div className="p-6 bg-white rounded-5xl">
          <QRCode value={`https://${currentDomain}/t/${TICKETID}`} />
        </div>
      </div>
      {/* MOBILE */}
      <div className="mt-10 md:block">
        <DownloadApps />
      </div>
      <div className="flex flex-col items-center justify-between md:hidden mt-12">
        <div className="text-center">
          <p className="text-white text-md font-americaLight">Add your ticket clicking the following button:</p>
        </div>
        <a href={`https://${currentDomain}/t/${TICKETID}`} className="w-full py-4 rounded-xl bg-green mt-12 block text-center">
          <span className="text-base text-black font-americaBold">Add your ticket</span>
        </a>
        <div className="text-center">
          <p className="text-white text-md font-americaLight mt-10">or manually add your ticket using the following code in the app:</p>
          {/* <span className="md:block text-white font-bold text-2xl">{TICKETID}</span> */}
          <button onClick={() => copyText()} className='flex gap-3 w-full items-center justify-center h-full'>
            <p className='text-white text-2xl font-semibold'>{TICKETID}</p>
            <div className='text-green'>{copy}</div>
          </button>
        </div>
      </div>
      {/* <div className="items-center justify-between w-full mt-24 md:w-1/3 flex">
        <a href="https://apps.apple.com/es/app/accessticket/id1534897902" className="flex-1 mr-2 md:mx-3">
          <img src="/assets/appstore.png" alt="AppStore" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=app.testwallet.accessticket" className="flex-1 ml-2 md:mx-3">
          <img src="/assets/playstore.png" alt="GooglePlay" />
        </a>
        <a href="https://appgallery.huawei.com/app/C105891447" className="flex-1 ml-2 md:mx-3">
          <img src="/assets/huawei.png" alt="Huawei" />
        </a>
      </div> */}
       <PrivacyButtons />

    </Container>
  )
}

export default Code